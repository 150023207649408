<template>
  <CustomControl :position="position" :class="`map-zoom-control ${position.toLowerCase()}`">
    <div class="map-zoom-control-container">
      <q-btn
        :disable="disableZoomIn"
        v-bind="btnProps"
        aria-label="zoom in"
        icon="mapZoomIn"
        class="map-zoom-control--btn map-zoom-control--btn-in"
        @click="onClickZoomIn"
      />
      <q-separator class="map-zoom-control--separator" />
      <q-btn
        :disable="disableZoomOut"
        v-bind="btnProps"
        aria-label="zoom out"
        icon="mapZoomOut"
        class="map-zoom-control--btn map-zoom-control--btn-out"
        @click="onClickZoomOut"
      />
    </div>
  </CustomControl>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import { CustomControl } from 'vue3-google-map';

import { UserMapActions } from '@/store/modules/search';

interface Props {
  map: google.maps.Map;
  position: (typeof CustomControl)['position'];
  zoomMax: number;
  zoomMin: number;
}
const props = defineProps<Props>();
const emit = defineEmits<{
  (e: 'userZoomedMap', userMapEvent: UserMapActions.ZOOMED_IN | UserMapActions.ZOOMED_OUT): void;
}>();

const zoomLevel = defineModel<number>({ required: true });

const disableZoomIn = computed(() => zoomLevel.value === props.zoomMax);
const disableZoomOut = computed(() => zoomLevel.value === props.zoomMin);

const onClickZoomIn = () => {
  if (disableZoomIn.value) return;
  const zoom = props.map.getZoom();
  if (typeof zoom === 'number') {
    zoomLevel.value = zoom;
    props.map.setZoom(zoomLevel.value + 1);
    emit('userZoomedMap', UserMapActions.ZOOMED_IN);
  }
};

const onClickZoomOut = () => {
  if (disableZoomOut.value) return;
  const zoom = props.map.getZoom();
  if (typeof zoom === 'number') {
    zoomLevel.value = zoom;
    props.map.setZoom(zoomLevel.value - 1);
    emit('userZoomedMap', UserMapActions.ZOOMED_OUT);
  }
};

const btnProps = {
  ripple: false,
  unelevated: true,
  dense: true,
};
</script>

<style lang="scss">
@use 'sass:map';

.map-zoom-control {
  .map-zoom-control-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: white;
    border-radius: 2px;
    border-radius: map.get($radius-sizes, xs);
    box-shadow: rgb(0 0 0 / 10%) 0 4px 12px;

    .map-zoom-control--btn {
      padding: 0.5rem;
      font-size: 0.625rem;
      color: #000;
    }

    .map-zoom-control--btn:disabled {
      color: $grey-4;
    }

    .map-zoom-control--separator {
      width: 70%;
      margin: 0 auto;
      background: $util-2;
    }
  }
}
</style>
