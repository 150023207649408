<template>
  <div v-if="listing" class="lp-category-attributes">
    <div
      ref="elLpCategoryTitle"
      class="listing-body__text--title"
      v-text="t('section.details.extraInfo')"
    />

    <div
      :class="{
        'listing-body__attributes-container': true,
        'listing-body__attributes-container--more': !showMore,
      }"
    >
      <div
        v-for="(sectionData, i) in listing.extraInformation.sections"
        :key="i"
        class="listing-details-extra-info"
      >
        <template v-if="sectionData.properties.length">
          <div
            :ref="el => (sectionRefs[i] = el as HTMLElement)"
            class="listing-details-extra-info-header--text"
            v-text="processedText(sectionData.label, sectionRefs[i])"
          />

          <div class="listing-details-extra-info-section">
            <div
              v-for="(property, j) in sectionData.properties"
              :key="j"
              class="listing-details-extra-info-body"
            >
              <div class="listing-details-extra-info-body--container">
                <span class="listing-body__text--label" v-text="property.label" />
                <span class="listing-body__text--value" v-text="property.value" />
              </div>
              <q-separator class="listing-body__separator" />
            </div>
          </div>
        </template>
      </div>
    </div>

    <q-btn
      v-if="showMoreBtn"
      outline
      no-caps
      unelevated
      class="listing__btn--show-more"
      :label="formatLabel(showMore ? 'showMore' : 'showLess', true)"
      @click="onClickBtnShowMore"
    />

    <div
      v-if="cans.includes('multi-unit')"
      class="listing-details-extra-info__multi-unit-container"
    >
      <q-list
        v-for="(unit, i) in listing.extraInformation.units"
        :key="i"
        class="listing-details-extra-info__multi-unit"
      >
        <q-expansion-item
          expand-separator
          expand-icon="arrow_drop_down"
          :label="`${ordinal(i + 1, $i18n.locale, 'female')} ${$t('unit')}`"
        >
          <q-card>
            <q-card-section class="listing-details-extra-info-section">
              <div
                v-for="(item, j) in unit"
                :key="`u-j-${j}`"
                class="listing-details-extra-info-body"
              >
                <div class="listing-details-extra-info-body--container">
                  <span class="listing-body__text--label" v-text="item.label" />
                  <span class="listing-body__text--value" v-text="item.value" />
                </div>
                <q-separator class="listing-body__separator" />
              </div>
            </q-card-section>
          </q-card>
        </q-expansion-item>
      </q-list>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useDebounceFn } from '@vueuse/core';
import { storeToRefs } from 'pinia';
import { scroll } from 'quasar';
import { onBeforeUnmount, onMounted, ref, useTemplateRef, watch } from 'vue';
import { useI18n } from 'vue-i18n';

import { useVarPool } from '@/elr/listing_page/var_pool';
import translations from '@/i18n/translations/components/listingPage.json';
import { useListingStore } from '@/store/modules/listing';
import { ordinal } from '@/utils/number';
import { formatLabel, processedText } from '@/utils/string';

const { getScrollTarget, setVerticalScrollPosition } = scroll;

const { t } = useI18n(translations);

const { listing } = storeToRefs(useListingStore());
const { cans } = useVarPool();

const MAX_HEIGHT = 310;
const showMoreBtn = ref(false);
const showMore = ref(true);

const localObserver = ref<IntersectionObserver>();

const isIntersecting = ref(false);
const elLpCategoryTitle = useTemplateRef<HTMLDivElement>('elLpCategoryTitle');

const sectionRefs = ref<(HTMLElement | null)[]>([]);

const onClickBtnShowMore = () => {
  showMore.value = !showMore.value;
};

const onResize = useDebounceFn(() => {
  let height = 0;
  document.querySelectorAll('.listing-details-extra-info').forEach(el => {
    height += el.clientHeight;
  });

  showMoreBtn.value = height > MAX_HEIGHT;
}, 150);

onMounted(() => {
  onResize();
  window.addEventListener('resize', onResize);
});

onBeforeUnmount(() => {
  localObserver.value?.disconnect();
  window.removeEventListener('resize', onResize);
});

watch(
  elLpCategoryTitle,
  v => {
    if (v) {
      localObserver.value = new window.IntersectionObserver(([entry]) => {
        isIntersecting.value = entry.isIntersecting;
      });

      localObserver.value.observe(v);
    }
  },
  {
    once: true,
  }
);

watch(showMore, v => {
  if (v && elLpCategoryTitle.value && !isIntersecting.value) {
    const target = getScrollTarget(elLpCategoryTitle.value);
    const offset = elLpCategoryTitle.value.offsetTop;

    setVerticalScrollPosition(target, offset, 0);
  }
});
</script>
