<template>
  <a class="app-nav-bar-logo" rel="noopener noreferrer" :href="hrefLocal" aria-label="home-url">
    <img alt="logo" :src="logoSrc" :width="logoWidth" :height="logoHeight" />
  </a>
</template>

<script setup lang="ts">
import { useQuasar } from 'quasar';
import { computed } from 'vue';

import Logo from '@/assets/logo.svg';
import LogoSmall from '@/assets/logo-small.svg';
import { useTheme } from '@/composables/theme';
import configApp from '@/config/app.json';

const { logo } = useTheme();
const { screen } = useQuasar();

const logoHeight = computed(() => (screen.lt.md ? logo.smallHeight : logo.height));
const logoWidth = computed(() => (screen.lt.md ? logo.smallWidth : logo.width));
const logoSrc = computed(() => (screen.lt.md ? LogoSmall : Logo));

const hrefLocal = computed(() =>
  process.env.NODE_ENV === 'development' ? '/' : configApp.company.links.home
);
</script>

<style lang="scss">
.app-nav-bar-logo {
  @media (min-width: $breakpoint-sm) {
    img {
      padding: 0.375rem 0;
    }
  }
}
</style>
