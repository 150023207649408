<template>
  <div v-if="options.length" class="column">
    <div class="sp-filter__label">
      {{ formatLabel(label) }}
    </div>
    <q-option-group v-model="selected" :options="unitInfoOptions" type="checkbox" inline>
      <template #label="opt">
        <div class="row items-center q-mx-md">
          <q-icon :name="opt.icon" size="1rem" class="q-my-sm" color="secondary" />
          <span class="q-ma-sm text-body2 text-secondary">
            {{ opt.label }}
          </span>
        </div>
      </template>
    </q-option-group>
  </div>
</template>

<script lang="ts" setup>
import { computed } from 'vue';

import { formatLabel } from '@/utils/string';

interface Props {
  modelValue: string[];
  label?: string;
  options: string[];
}

const props = withDefaults(defineProps<Props>(), {
  modelValue: () => [],
  label: 'unitInfo',
});

const emit = defineEmits<{
  (event: 'update:modelValue', choices: string[]): void;
}>();

const unitInfoOptions = computed(() =>
  props.options.map(option => ({
    label: formatLabel(option, false, false, option === 'myHome2'),
    value: option,
    icon: option === 'myHome2' ? 'house' : option.replace(/[\W]/g, ''),
  }))
);

const selected = computed({
  get: () => props.modelValue,
  set: newValue => {
    emit('update:modelValue', newValue);
  },
});
</script>

<style lang="scss" scoped>
@use 'sass:map';

.q-option-group :deep(.q-checkbox) {
  margin-bottom: 0.5rem;
  border: solid 1px $util-2;
  border-radius: map.get($radius-sizes, lg);
}

.q-option-group :deep(.q-checkbox__inner) {
  display: none;
}

.q-option-group :deep(.q-checkbox[aria-checked='true']) {
  border: solid 1px $secondary;
}
</style>
