import _get from 'lodash/get';
import { format } from 'quasar';

import i18n from '@/i18n';
import type { ListingAmenitiesSchemaItem } from '@/types';
import type { ListingAmenitiesData, ListingData } from '@/types/api/listing';
import { humanReadableLabel } from '@/utils/format';
import { ordinal as makeOrdinal, toThousands } from '@/utils/number';
import { isUntranslatable, trans } from '@/utils/string';

export default class ListingAmenitiesBuilder {
  public listingData;

  private _isMultiLevel;

  private _isMultiUnit;

  private _locale;

  private _schema!: Record<string, ListingAmenitiesSchemaItem>;

  constructor(listingData: ListingData, isMultiUnit: boolean, isMultiLevel: boolean) {
    this.listingData = listingData;
    this._isMultiLevel = isMultiLevel;
    this._isMultiUnit = isMultiUnit;

    this._locale = i18n.global.locale.value;
  }

  public set schema(schema: Record<string, ListingAmenitiesSchemaItem>) {
    this._schema = schema;
  }

  public build(): ListingAmenitiesData[] {
    if (!this._schema) throw new Error('Schema is not set');

    const amenities: ListingAmenitiesData[] = [];

    Object.keys(this._schema).forEach((key: string) => {
      const amenity = this.makeAmenityItem(key);
      amenities.push(amenity);
    });

    return amenities.filter(amenity => !this.isInvalidAmenity(amenity));
  }

  private makeAmenityItem(key: string): ListingAmenitiesData {
    const { icon, path, ordinal, suffix, unit, type } = this._schema[key];
    const value = _get(this.listingData, path);
    const labelSuffix = suffix ? trans(suffix, true, Number.isFinite(value) ? value : null) : null;
    const { t } = i18n.global;

    let theValue;
    if (key === 'floors') {
      if (value.length > 1) {
        theValue = `${value.length} ${format.capitalize(t('level', 2))}`;
      } else {
        theValue = humanReadableLabel('floor', value[0], type, unit);
      }
    } else {
      theValue = ordinal ? makeOrdinal(value, this._locale) : value;
    }

    if (key === 'size') theValue = toThousands(value);

    let valueUnit = '';
    if (unit) {
      valueUnit = isUntranslatable(unit) ? unit : trans(unit, true);
    }

    const label = [theValue, valueUnit, labelSuffix || '']
      .filter(v => !['', undefined, null].includes(v))
      .join(' ');

    return {
      icon,
      label,
      name: key,
      value,
    };
  }

  private isInvalidAmenity(amenity: ListingAmenitiesData): boolean {
    const visibleInMultiUnit = this._schema[amenity.name].multiUnit;
    const visibleInMultiLevel = this._schema[amenity.name].multiLevel;
    if (this._isMultiUnit && !visibleInMultiUnit) return true;
    if (this._isMultiLevel && !visibleInMultiLevel) return true;

    if (typeof amenity.value === 'number' && Number.isFinite(amenity.value)) {
      const empty = amenity.value === 0;
      const belowMin = ['levels', 'units'].includes(amenity.name) && amenity.value < 2;

      if (empty) return true;

      if (belowMin) return true;

      return false;
    }

    const isEmpty =
      amenity.value == null ||
      (Array.isArray(amenity.value) && amenity.value.length === 0) ||
      (typeof amenity.value === 'object' && Object.keys(amenity.value).length === 0) ||
      (typeof amenity.value === 'object' &&
        Array.isArray(Object.values(amenity.value)) &&
        Object.values(amenity.value).every(v => v === null));

    return isEmpty;
  }
}
