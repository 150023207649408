<template>
  <div class="row wrap justify-start items-stretch content-center">
    <div class="col row wrap content-center text-subtitle1 text-secondary">
      {{ formatLabel(label, true) }}
    </div>
    <q-toggle
      v-model="selectedLocal"
      true-value="comingSoon"
      size="42px"
      @update:model-value="onUpdateModelValue"
    />
  </div>
</template>

<script lang="ts" setup>
import { computed, ref } from 'vue';

import { formatLabel } from '@/utils/string';

const props = defineProps<{
  modelValue?: string[];
  label?: string;
}>();

const emit = defineEmits<{
  (e: 'update:modelValue', v?: string[]): void;
}>();

const selected = computed({
  get: () => props.modelValue,
  set: v => {
    emit('update:modelValue', v);
  },
});

const selectedLocal = ref(selected.value?.length === 1 && selected.value[0]);

const onUpdateModelValue = () => {
  const value = selectedLocal.value || undefined;

  selected.value = (value && [value]) || [];
};
</script>
