<template>
  <div v-if="options.length" class="column">
    <div class="sp-filter__label" v-text="text" />
    <q-option-group v-model="selected" :options="typeOptions" type="checkbox" inline>
      <template #label="opt">
        <div class="row items-center justify-center">
          <span class="q-px-md q-py-sm text-body2 text-secondary">
            {{ opt.label }}
          </span>
        </div>
      </template>
    </q-option-group>
  </div>
</template>

<script lang="ts" setup>
import { computed } from 'vue';

import { formatLabel } from '@/utils/string';

interface Props {
  modelValue: string[];
  label?: string;
  options: string[];
}

const props = withDefaults(defineProps<Props>(), {
  modelValue: () => [],
  label: 'type',
});
const emit = defineEmits<{
  (event: 'update:modelValue', choices: string[]): void;
}>();
const typeOptions = computed(() =>
  props.options.map(value => ({
    label: formatLabel(value),
    value,
  }))
);

const selected = computed({
  get: () => props.modelValue,
  set: v => {
    emit('update:modelValue', v);
  },
});

const text = computed(() => formatLabel(props.label));
</script>

<style lang="scss" scoped>
@use 'sass:map';

.q-option-group :deep(> div) {
  margin-bottom: 8px;
}

.q-option-group :deep(.q-checkbox) {
  min-width: 100%;
  border: solid 1px $util-2;
  border-radius: map.get($radius-sizes, lg2);
}

.q-option-group :deep(.q-checkbox__label) {
  min-width: 100%;
}

.q-option-group :deep(.q-checkbox__inner) {
  display: none;
}

.q-option-group :deep(.q-checkbox[aria-checked='true']) {
  border: solid 1px $secondary;
}
</style>
