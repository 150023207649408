// TODO: PLEASE REFACTOR ME
import collect, { Collection } from 'collect.js';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import isNil from 'lodash/isNil';
import queryString, { type ParsedQuery } from 'query-string';
import type { RouteLocationNormalized } from 'vue-router';

import { componentsMap, type FilterDataTransformers } from '@/components/filterComponentsMap';
import { useSearchStore } from '@/store/modules/search';
import type { NonNullableQuery, SearchFilter } from '@/types';
import { SearchSortByParam } from '@/types';

const allowedFilters = new Set(Object.keys(componentsMap));

function getQueryStringFromRoute(route: RouteLocationNormalized): string {
  return route.fullPath.replace(route.path, '');
}

export const filtersHandler = {
  handle(routeTo: RouteLocationNormalized, routeFrom: RouteLocationNormalized): void {
    if (routeTo.name !== 'search') return;

    const searchStore = useSearchStore();

    searchStore.loading = true;

    searchStore.setStaticFiltersFromRoute(routeTo.params);
    if (searchStore.staticFiltersChanged(routeFrom.params)) {
      searchStore.resetFilters();
    }

    const query: string = getQueryStringFromRoute(routeTo);

    const filtersFromUrl: ParsedQuery<string | boolean | number> = this.getFiltersFromUrl(query, [
      'locations',
    ]);

    const filters: NonNullableQuery = this.normalizeDataFromUrl(filtersFromUrl);
    const locationsFromUrl: string[] = this.getLocationsFromUrl(query);
    const pageFromUrl: number = this.getPageFromUrl(query);
    const sortByFromUrl: SearchSortByParam = this.getSortByFromUrl(query);

    if (!isEmpty(locationsFromUrl) && this.locationsChanged(locationsFromUrl)) {
      searchStore.setLocationsFilter(locationsFromUrl);
    }

    if (this.filtersChanged(filters)) {
      searchStore.setFilters(filters as SearchFilter);
    }

    if (this.pageChanged(pageFromUrl)) {
      searchStore.page = pageFromUrl;
    }

    if (this.sortByChanged(sortByFromUrl)) {
      searchStore.sortBy = sortByFromUrl;
    }

    searchStore.previousStaticFilters = { ...searchStore.staticFilters };
    searchStore.previousFilters = { ...searchStore.filters };

    searchStore.applyFilters({});
  },
  filtersChanged(filtersFromUrl: NonNullableQuery): boolean {
    const searchStore = useSearchStore();
    const filtersFromStore = {
      ...searchStore.filters,
      ...{ locations: searchStore.staticFilters.locations },
    };

    return !isEqual(filtersFromUrl, filtersFromStore);
  },
  pageChanged(pageFromUrl: number): boolean {
    const searchStore = useSearchStore();
    const pageFromStore = searchStore.page;

    return pageFromUrl !== pageFromStore;
  },
  sortByChanged(sortByFromUrl: SearchSortByParam): boolean {
    const searchStore = useSearchStore();
    const sortByFromStore = searchStore.sortBy;

    return sortByFromUrl !== sortByFromStore;
  },
  getFiltersFromUrl(query: string, exclude: string[] = []) {
    const qString: string = queryString.exclude(query, exclude);

    const parsed: ParsedQuery<string | boolean | number> = queryString.parse(qString, {
      parseNumbers: true,
      parseBooleans: true,
      arrayFormat: 'comma',
    });

    return parsed;
  },
  getLocationsFromUrl(query: string): string[] {
    const qString: string = queryString.pick(query, ['locations']);
    const parsed: ParsedQuery = queryString.parse(qString, {
      arrayFormat: 'comma',
    });

    if (isEmpty(parsed.locations) || isNil(parsed.locations)) {
      return [];
    }

    if (Array.isArray(parsed.locations)) {
      return parsed.locations.filter(a => !isNil(a) && typeof a !== 'number') as string[];
    }
    return Array(parsed.locations).filter(a => !isNil(a) && typeof a !== 'number');
  },
  getPageFromUrl(query: string): number {
    const qString: string = queryString.pick(query, ['page']);
    const parsed: ParsedQuery = queryString.parse(qString, {
      arrayFormat: 'comma',
    });
    const pattern = /[0-9]/;
    if (!isEmpty(parsed.page) && !isNil(parsed.page)) {
      if (pattern.test(parsed.page as string)) return +parsed.page;
    }

    return 1;
  },
  getSortByFromUrl(query: string): SearchSortByParam {
    const qString: string = queryString.pick(query, ['sortBy']);
    const parsed: ParsedQuery = queryString.parse(qString, {
      arrayFormat: 'comma',
    });
    if (!isEmpty(parsed.sortBy) && !isNil(parsed.sortBy)) {
      if (
        Object.values(SearchSortByParam).includes(parsed.sortBy as unknown as SearchSortByParam)
      ) {
        return parsed.sortBy as unknown as SearchSortByParam;
      }
    }

    return SearchSortByParam.Default;
  },
  isValidFilter(needle: string): boolean {
    return allowedFilters.has(needle);
  },
  removeInvalid(
    collection: Collection<ParsedQuery | ParsedQuery<string | boolean | number>>
  ): Collection<ParsedQuery | ParsedQuery<string | boolean | number>> {
    const validKeys = collection.keys().reject(key => !this.isValidFilter(key));
    return collection.only(validKeys.toArray());
  },
  locationsChanged(locationsFromUrl: string[]): boolean {
    const searchStore = useSearchStore();
    const locationsFromStore = searchStore.staticFilters.locations;

    return !isEqual(locationsFromUrl, locationsFromStore);
  },
  normalizeDataFromUrl(
    filtersFromUrl: ParsedQuery | ParsedQuery<string | boolean | number>
  ): NonNullableQuery {
    const filters = collect({ ...filtersFromUrl });

    // collection can also be an object not always array
    return filters
      .pipe(items => this.removeInvalid(items))
      .pipe(items => this.sanitizeFiltersFromUrl(items))
      .all() as unknown as NonNullableQuery;
  },
  sanitizeFiltersFromUrl(
    filtersFromUrl: Collection<ParsedQuery | ParsedQuery<string | boolean | number>>
  ): Collection<NonNullableQuery> {
    return (
      filtersFromUrl
        // Something is wrong with the declared type transform<T>(fn: (item: Item) => T): Collection<T>;
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        .transform((item: (typeof ParsedQuery)[keyof typeof ParsedQuery], key: string) => {
          let clone = item;
          const transformers: FilterDataTransformers = componentsMap[key].transform;
          for (let i = 0; i < transformers.length; i += 1) {
            clone = transformers[i](clone);
          }

          return clone;
        })
        .reject(value => {
          if (typeof value === 'boolean') return false;

          return isNil(value) || isEmpty(value);
        }) as unknown as Collection<NonNullableQuery>
    );
  },
};
