<template>
  <q-header>
    <div v-if="$q.screen.lt.md" class="mb-nav-bar">
      <div class="mb-nav-bar--content left-side">
        <AppNavBarLogo class="row items-center" />
      </div>

      <div class="mb-nav-bar--content middle">
        <SPLocations v-if="primarySearchFiltersVisible" />
      </div>

      <div class="mb-nav-bar--content right-side">
        <template v-if="!['list-property', 'login'].includes(String(route.name))">
          <GlobalAvatar v-if="user" size="2rem" @click="toggleDrawer" />

          <q-btn
            v-else
            color="secondary"
            dense
            flat
            icon="menu"
            unelevated
            class="mb-nav-bar__toggle-btn"
            @click="toggleDrawer"
          />
        </template>

        <AppLanguageSwitcher
          v-if="
            configLocalization.availableLocales.length > 1 &&
            ['list-property', 'login'].includes(String(route.name))
          "
          @hide="toggleOverlay"
          @show="toggleOverlay"
        />
      </div>
    </div>

    <div v-else class="dp-nav-bar">
      <div class="dp-nav-bar--content left-side">
        <AppNavBarLogo class="row items-center" />
      </div>

      <div class="dp-nav-bar--content middle">
        <template v-if="primarySearchFiltersVisible">
          <SPFiltersCommercializationType
            v-if="commercializationTypes.length > 1"
            class="dp-nav-bar-item"
            :q-btn-dropdown-props="qBtnDropdownProps"
            @hide="toggleOverlay"
            @show="toggleOverlay"
          />

          <SPFiltersCategory
            class="dp-nav-bar-item"
            :q-btn-dropdown-props="qBtnDropdownProps"
            @hide="toggleOverlay"
            @show="toggleOverlay"
          />

          <SPLocations class="dp-nav-bar-item" @show="toggleOverlay" @hide="toggleOverlay" />
        </template>
      </div>

      <div class="dp-nav-bar--content right-side">
        <AppLanguageSwitcher
          v-if="configLocalization.availableLocales.length > 1"
          class="dp-nav-bar-item"
          :q-btn-dropdown-props="qBtnDropdownProps"
          @hide="toggleOverlay"
          @show="toggleOverlay"
        />

        <AppListPropertyBtn
          v-if="
            featureConfig.global.listingSubmission &&
            !['list-property', 'login'].includes(String(route.name))
          "
          class="dp-nav-bar-item"
        />

        <AppProfileMenu class="dp-nav-bar-item" :q-btn-dropdown-props="qBtnDropdownProps" />
      </div>
    </div>
  </q-header>
</template>

<script setup lang="ts">
import { useDebounceFn } from '@vueuse/core';
import { storeToRefs } from 'pinia';
import { QBtn, type QBtnDropdownProps, Screen } from 'quasar';
import { computed, onBeforeUnmount, onMounted } from 'vue';
import { useRoute } from 'vue-router';

import AppLanguageSwitcher from '@/components/AppCommon/AppLanguageSwitcher.vue';
import AppListPropertyBtn from '@/components/AppCommon/AppListPropertyBtn.vue';
import AppNavBarLogo from '@/components/AppCommon/AppNavBarLogo.vue';
import AppProfileMenu from '@/components/AppCommon/AppProfileMenu.vue';
import SPFiltersCategory from '@/components/SearchPage/Filters/SPFiltersCategory.vue';
import SPFiltersCommercializationType from '@/components/SearchPage/Filters/SPFiltersCommercializationType.vue';
import SPLocations from '@/components/SearchPage/Locations/SPLocations.vue';
import { useFeatureConfig } from '@/composables/featureConfig';
import configLocalization from '@/config/localization.json';
import configTheme from '@/config/theme.json';
import useAppStore from '@/store/modules/app';
import useAuthStore from '@/store/modules/auth';

const route = useRoute();
const { layoutDrawer, layoutOverlay, commercializationTypes } = storeToRefs(useAppStore());
const { user } = storeToRefs(useAuthStore());
const { featureConfig } = useFeatureConfig();

const qBtnDropdownProps = configTheme.components.AppNavBar.qBtnDropdownProps as QBtnDropdownProps;
const appNavBarLayoutOverlay = configTheme.components.AppNavBar.layoutOverlay;

const primarySearchFiltersVisible = computed(() => route.name === 'search');

const toggleDrawer = () => {
  layoutDrawer.value = !layoutDrawer.value;
};
const toggleOverlay = () => {
  layoutOverlay.value = appNavBarLayoutOverlay ? !layoutOverlay.value : appNavBarLayoutOverlay;
};

const onResize = useDebounceFn(() => {
  if (layoutDrawer.value || layoutOverlay.value) {
    if (Screen.gt.sm) {
      layoutDrawer.value = false;
    }
    if (Screen.lt.md) {
      layoutOverlay.value = false;
    }
  }
}, 100);

onMounted(() => {
  onResize();
  window.addEventListener('resize', onResize);
});

onBeforeUnmount(() => {
  window.removeEventListener('resize', onResize);
});
</script>
