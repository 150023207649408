<template>
  <div
    ref="elSearchResultsList"
    :class="{
      'search-results-list': true,
      'no-results': noResultsFound,
    }"
  >
    <div v-show="searchStore.loadingState" class="search-results-list--grid">
      <SPListItemSkeleton v-for="i in 8" :key="i" :cols="searchResultsGridCols" />
    </div>

    <SPListNoData v-if="noResultsFound" />

    <!-- Results list grid -->
    <div
      v-show="!searchStore.loadingState"
      :class="{
        'search-results-list--grid': true,
        'exact-match-results': exactMatch,
      }"
    >
      <SPListItem
        v-for="listing in searchStore.results.listings"
        :key="listing.id"
        :listing="ListingMapper.fromSearchResult(listing)"
      />

      <!-- Exact match item -->
      <template v-if="exactMatch">
        <SPListExactMatch :property-code="exactMatch.propertyCode" :listing="exactMatch" />

        <q-space
          v-if="
            ($q.screen.lt.md &&
              !$q.screen.xs &&
              (searchStore.results.listings.length % 2 !== 0 || exactMatch)) ||
            searchStore.results.listings.length % 2 !== 0
          "
        />
      </template>
    </div>

    <q-pagination
      v-show="!searchStore.loadingState && lastPage > 1"
      boundary-links
      class="search-results-list--pagination"
      color="secondary"
      direction-links
      :max="lastPage"
      :max-pages="$q.screen.xs ? 5 : 9"
      :model-value="searchStore.page"
      size="0.8rem"
      v-bind="qPaginationProps"
      :to-fn="(page: number) => ({ query: { ...route.query, page } })"
      @update:model-value="updatePage($event)"
    />

    <div class="row justify-center q-pb-lg">
      <AppBreadcrumbs :breadcrumbs="searchStore.meta?.breadcrumbs" />
    </div>
  </div>
</template>

<script setup lang="ts">
import type { QPaginationProps } from 'quasar';
import { computed, useTemplateRef } from 'vue';
import { useRoute } from 'vue-router';

import AppBreadcrumbs from '@/components/AppCommon/AppBreadcrumbs.vue';
import SPListItem from '@/components/SearchPage/List/Item/SPListItem.vue';
import SPListItemSkeleton from '@/components/SearchPage/List/Item/SPListItemSkeleton.vue';
import SPListExactMatch from '@/components/SearchPage/List/SPListExactMatch.vue';
import SPListNoData from '@/components/SearchPage/List/SPListNoData.vue';
import { useTheme } from '@/composables/theme';
import ListingMapper from '@/mappers/listingMapper';
import { useSearchStore } from '@/store/modules/search';

withDefaults(
  defineProps<{
    offsetTop: string;
    qPaginationProps?: QPaginationProps;
  }>(),
  { offsetTop: '0px' }
);

const route = useRoute();

const elSearchResultsList = useTemplateRef<HTMLDivElement>('elSearchResultsList');

const { searchResultsGridCols } = useTheme();
const searchStore = useSearchStore();

const lastPage = computed(() => searchStore.meta.last_page);

const exactMatch = computed(
  () =>
    searchStore.results.exactMatches[0] &&
    ListingMapper.fromSearchResult(searchStore.results.exactMatches[0])
);

const noResultsFound = computed(
  () => searchStore.results.listings.length === 0 && !searchStore.loadingState && !exactMatch.value
);

const updatePage = (page: number) => searchStore.$patch({ page });
</script>

<style lang="scss">
.search-results-list {
  position: relative;
  top: v-bind('offsetTop');
  width: 100%;

  .search-results-list--grid {
    display: grid;
    grid-template-columns: repeat(v-bind('searchResultsGridCols'), minmax(0, 1fr));
    gap: 1rem;
    padding: 1rem;
    background: inherit;
  }

  .search-results-list--pagination {
    width: 100%;
    font-size: 0.8rem;

    > * {
      flex-grow: 1;
      justify-content: center;
      padding: 1.5rem 0;
    }

    .q-icon {
      font-size: inherit;
    }

    > :nth-child(-n + 2) {
      margin-right: 1.5rem !important;
      @media (width <= 390px) {
        margin-right: 0.5rem !important;
      }
    }

    > :nth-last-child(-n + 2) {
      margin-left: 1.5rem !important;
      @media (width <= 390px) {
        margin-left: 0.5rem !important;
      }
    }
  }

  &.no-results {
    padding: 1rem;

    .search-results-list--grid {
      display: none;
    }

    .search-results-list--item {
      grid-template-columns: 1fr;
    }
  }
}
</style>
