import { createI18n } from 'vue-i18n';

import configLocalization from '@/config/localization.json';

const mods = import.meta.glob('@/i18n/translations/*.json', { eager: true });
const messages: Record<string, Record<string, string>> = {};
configLocalization.availableLocales.forEach(locale => {
  Object.entries(mods).forEach(([key, value]) => {
    if (key.includes(`${locale}.json`)) {
      messages[locale] = { ...JSON.parse(JSON.stringify(value)) };
    }
  });
});

const i18n = createI18n({
  legacy: false,
  locale: configLocalization.defaultLocale,
  fallbackLocale: configLocalization.defaultLocale,
  globalInjection: true,
  messages,
  dateTimeFormats: configLocalization.dateTimeFormats,
  // @todo check if newer version fixes the issue of the numberFormat type
  numberFormats: {
    en: {
      currency: {
        style: 'currency',
        currencyDisplay: 'symbol',
        currency: 'EUR',
        maximumFractionDigits: 0,
        minimumFractionDigits: 0,
      },
    },
    el: {
      currency: {
        style: 'currency',
        currencyDisplay: 'symbol',
        currency: 'EUR',
        maximumFractionDigits: 0,
        minimumFractionDigits: 0,
      },
    },
  },
});

export default i18n;
