import { useMeta } from 'quasar';
import { provide, ref } from 'vue';
import { useRouter } from 'vue-router';

import { useApiAuth } from '@/composables/api/auth';
import { useApiClient } from '@/composables/api/client';
import { useNotifications } from '@/composables/useNotifications';
import themeConfig from '@/config/theme.json';
import { cachePool as cachePoolPageListing } from '@/elr/listing_page/cacheFactory';
import { useVarPool } from '@/elr/listing_page/var_pool';
import { cachePool as cachePoolPageSearch } from '@/elr/search_page/cacheFactory';
import useAuthStore from '@/store/modules/auth';
import { elrCachePoolKey } from '@/types/expressionLanguage';

export const useBoot = () => {
  const router = useRouter();
  const authStore = useAuthStore();
  const { csrfToken } = useApiAuth();
  const { apiClient, error } = useApiClient();
  const { notifyDeleteUser } = useNotifications();

  const link = ref<Record<string, { href: string; rel: string }>>({});
  const { typography, components } = themeConfig;

  const applyAxiosInterceptors = () => {
    apiClient.interceptors.response.use(
      res => res,
      async err => {
        const status = Number(err?.response?.status || '500');

        if (process.env.NODE_ENV === 'development' && status !== 401) {
          return Promise.reject(err);
        }

        switch (status) {
          case 400:
          case 404:
          case 500:
            error.value = true;
            router.replace({ name: 'error', params: { status } });
            break;

          case 401:
            authStore.user = null;
            break;

          case 419:
            await csrfToken();
            return apiClient.request(err.config);

          default:
            break;
        }

        return Promise.reject(err);
      }
    );
  };

  const applyCss = () => {
    Promise.all([
      import(`@/css/navbar/${components.AppNavBar.cssUrl}/index.scss`),
      import(`@/css/listing/${components.SPListItem.cssUrl}/index.scss`),
    ])
      .then(([navBarModule, spListItemModule]) => {
        const navBarStyle = document.createElement('style');
        navBarStyle.innerHTML = navBarModule.default;
        document.head.appendChild(navBarStyle);

        const spListItemStyle = document.createElement('style');
        spListItemStyle.innerHTML = spListItemModule.default;
        document.head.appendChild(spListItemStyle);
      })
      .catch(e => {
        console.error('Error loading CSS:', e);
      });

    if (typography.fontFamilyCdnUrl) {
      link.value.fontFamilyCdn = {
        rel: 'stylesheet',
        href: typography.fontFamilyCdnUrl,
      };
    }

    document.documentElement.style.setProperty('--font-family', typography.fontFamily);
  };

  const bootApp = () => {
    applyAxiosInterceptors();

    applyCss();

    useMeta({ link: link.value });

    notifyDeleteUser();
  };

  const bootListing = () => {
    useVarPool().bootPage();

    provide(elrCachePoolKey, cachePoolPageListing);
  };

  const bootSearch = () => {
    import(`@/css/page/search/${components.SearchPage.cssUrl}/index.scss`)
      .then(module => {
        const style = document.createElement('style');
        style.innerHTML = module.default;
        document.head.appendChild(style);
      })
      .catch(e => {
        console.error('Error loading CSS:', e);
      });

    provide(elrCachePoolKey, cachePoolPageSearch);
  };

  return { bootApp, bootListing, bootSearch };
};
