<template>
  <div
    v-if="listing"
    :class="{
      'listing--acquired': cans.includes('acquired') || cans.includes('underOffer'),
    }"
  >
    <div class="listing-header">
      <div class="listing-header__container">
        <div class="listing-header-info">
          <div class="listing-header-info__container">
            <div class="listing-header-info__container--top">
              <BadgesRenderer :attributes="badges" />

              <q-space />

              <div class="listing-header-actions">
                <ShareModalBtn
                  class="listing__btn--share"
                  :q-btn-props="{ icon: 'share', disable: isPreview }"
                />

                <FavoritesBtn
                  :label="
                    tListingPage(`btn.favorites.${isFavorite(listing.id) ? 'remove' : 'add'}`)
                  "
                  :listing-id="listing.id"
                  :disable="isPreview"
                />
              </div>
            </div>

            <div class="listing-header-info__container--above-title">
              <template v-if="listingData?.propertyInfo.building?.hotelRating">
                <q-icon
                  v-for="i in +listingData.propertyInfo.building.hotelRating"
                  :key="i"
                  class="listing-hotel-rating__icon"
                  :name="icons.hotelRating"
                />
              </template>

              <div class="listing-code__text" v-text="formatPropertyCode(listing.propertyCode)" />
            </div>

            <div class="listing-title__text" v-text="listing.title" />

            <div class="listing-header-info__container--below-title">
              <div
                class="listing-price__text"
                v-text="
                  cans.includes('price-upon-request')
                    ? $t('priceUponRequest')
                    : formatPrice(listing.price)
                "
              />

              <PriceChangeIndicator
                v-if="!cans.includes('price-upon-request')"
                :price-before="listing.originalPrice"
                :price-current="listing.price"
              />

              <ImgRibbon
                v-if="cans.includes('acquired') || cans.includes('underOffer')"
                :text="ribbonText"
              />
            </div>

            <q-separator class="listing-header-info__separator" />

            <AttributesRenderer
              v-if="!!amenities.length"
              :attributes="amenities"
              class="listing-amenities"
            />

            <Teleport
              v-if="cans.includes('lp-interest')"
              :disabled="$q.screen.gt.sm"
              to=".layout-main-footer__body"
            >
              <q-btn
                class="listing__btn--interest"
                :disable="submittedInterest || loadingInterest"
                :icon="submittedInterest ? 'check' : undefined"
                :label="tListingPage(`btn.interest.${submittedInterest ? 'submitted' : 'submit'}`)"
                no-caps
                no-wrap
                outline
                @click="onClickDialogFormInterest"
              />
            </Teleport>

            <Teleport
              v-if="!cans.includes('acquired') && cans.includes('auction')"
              to=".layout-main-footer__head"
            >
              <div v-show="$q.screen.lt.md" class="listing-auction-bid__container">
                <div class="listing-auction-bid__title" v-text="auctionTitle" />
              </div>
            </Teleport>
          </div>
        </div>

        <q-space />

        <LPMediaCarousel
          :controls="$q.screen.gt.xs"
          :exclude-controls="['photos', 'areaVideo', 'mapPhotos']"
        />
      </div>
    </div>

    <div class="listing-body">
      <div class="listing-body-details">
        <div class="listing-body-section">
          <div class="listing-body__text--title" v-text="tListingPage('section.details.title')" />

          <template v-if="!!listing.listingInfo.description">
            <div
              class="listing-body__text--subtitle"
              v-text="tListingPage('section.details.description')"
            />

            <div>
              <div
                ref="elListingBodyDescription"
                :class="['listing-description__container', { 'show-more': descriptionBtnShowMore }]"
              >
                <div
                  v-dompurify-html="listing.listingInfo.description.trimEnd()"
                  class="listing-body__text--label"
                />
              </div>

              <q-btn
                v-if="descriptionBtnShowMoreVisible"
                class="listing__btn--show-more-description"
                icon-right="arrow_drop_down"
                :label="capitalize($t(descriptionBtnShowMore ? 'showLess' : 'showMore'))"
                no-caps
                :ripple="false"
                unelevated
                @click="onClickBtnDescriptionShowMore"
              />
            </div>

            <div v-if="!!listing.listingInfo.disclaimer?.length">
              <div
                v-for="(disclaimer, i) in listing.listingInfo.disclaimer"
                :key="i"
                v-dompurify-html="`*${disclaimer}`"
                class="listing-disclaimer__text"
              />
            </div>
          </template>

          <LPDetailsExtraInfo
            v-if="cans.includes('extra-info')"
            :extra-info="listing.extraInformation"
          />
        </div>

        <div v-if="cans.includes('lro')" class="listing-body-section">
          <span
            class="listing-body__text--subtitle"
            v-text="tListingPage('section.landRegistrationDetails.title')"
          />

          <Transition name="fade-in">
            <LPFormUnlockLandRegistrationDetails v-if="!user" />
            <LPLandRegistrationDetails v-else />
          </Transition>
        </div>

        <div v-if="cans.includes('auction')" class="listing-body-section">
          <div class="listing-body__text--title" v-text="tListingPage('section.auction.title')" />
          <div class="listing-body-details--auction-info">
            <div :class="{ 'listing-body-details--auction-info__content': user }">
              <span class="q-mr-xs" v-text="auctionInfoLabel" />
              <a
                href=""
                @click.prevent="onClickAuctionInfo"
                @keyup.enter.prevent="onClickAuctionInfo"
                v-text="auctionInfoBtn"
              />
            </div>

            <div v-if="user" class="listing-body-details--auction-info__content">
              <div v-text="tListingPage('section.auction.headerId')" />
              <div
                class="listing-auction-id__value"
                v-text="listing.auction?.currentRound.auctionId"
              />
            </div>
          </div>

          <template v-if="cans.includes('auction-siblings')">
            <div
              class="listing-body-details--auction-siblings"
              v-text="tListingPage('section.auction.siblings')"
            />
            <LPAuctionSiblings />
          </template>
        </div>

        <LPUnlockDocuments v-if="cans.includes('documents')" />

        <LPMortgage v-if="cans.includes('lp-mortgage')" />

        <div v-if="locationInfoBlocks.length" class="listing-body-section">
          <div
            class="listing-body__text--title"
            v-text="tListingPage('section.location.area.title')"
          />

          <template v-for="(block, i) in locationInfoBlocks" :key="i">
            <component
              :is="block.component"
              v-if="block.visible ?? true"
              class="listing-body-section"
            />
          </template>
        </div>
      </div>

      <q-space v-if="$q.screen.gt.sm" />

      <div v-show="$q.screen.gt.sm" ref="elCtaWidgetContainer" class="listing-form-widget">
        <FormStepper
          v-if="cans.includes('lp-auction-bid')"
          class="lp-auction-bid__form"
          :factory-id="factoryIdLpAuctionBid"
          flat
          :steps="formStepsLpAuctionBid"
        />
      </div>
    </div>

    <LPSuggestionsSimilarCarousel v-if="cans.includes('similar')" />

    <DialogFormStepper
      v-if="dialogFormFactoryId"
      v-model="dialog"
      :factory-id="dialogFormFactoryId"
      persistent
      :steps="dialogFormSteps"
    />
  </div>
</template>

<script setup lang="ts">
import { useElementBounding, useEventBus } from '@vueuse/core';
import { storeToRefs } from 'pinia';
import { format, Screen } from 'quasar';
import { computed, nextTick, onBeforeUnmount, onMounted, ref, useTemplateRef, watch } from 'vue';
import { useI18n } from 'vue-i18n';

import AttributesRenderer from '@/components/AttributesRenderer.vue';
import BadgesRenderer from '@/components/BadgesRenderer.vue';
import DialogFormStepper from '@/components/Dialog/DialogFormStepper.vue';
import FormStepper from '@/components/Form/FormStepper.vue';
import FavoritesBtn from '@/components/Global/FavoritesBtn.vue';
import LPFormUnlockLandRegistrationDetails from '@/components/ListingPage/Form/LPFormUnlockLandRegistrationDetails.vue';
import LPAddress from '@/components/ListingPage/fragments/LPAddress.vue';
import LPAuctionSiblings from '@/components/ListingPage/fragments/LPAuctionSiblings.vue';
import LPDetailsExtraInfo from '@/components/ListingPage/fragments/LPDetailsExtraInfo.vue';
import LPLandRegistrationDetails from '@/components/ListingPage/fragments/LPLandRegistrationDetails.vue';
import LPMediaCarousel from '@/components/ListingPage/fragments/LPMediaCarousel.vue';
import LPMortgage from '@/components/ListingPage/fragments/LPMortgage.vue';
import LPSuggestionsSimilarCarousel from '@/components/ListingPage/fragments/LPSuggestionsSimilarCarousel.vue';
import LPUnlockDocuments from '@/components/ListingPage/fragments/LPUnlockDocuments.vue';
import LPPoisMap from '@/components/ListingPage/Pois/LPPoisMap.vue';
import LPPoisProximity from '@/components/ListingPage/Pois/LPPoisProximity.vue';
import ImgRibbon from '@/components/Reusable/ImgRibbon.vue';
import PriceChangeIndicator from '@/components/Reusable/PriceChangeIndicator.vue';
import ShareModalBtn from '@/components/Reusable/ShareModalBtn.vue';
import { useFeatureConfig } from '@/composables/featureConfig';
import { useTheme } from '@/composables/theme';
import { useFavorites } from '@/composables/useFavorites';
import { useVarPool } from '@/elr/listing_page/var_pool';
import { useStepsLpAuctionBid } from '@/factories/formStepsFactory/lpAuctionBid';
import { useStepsLpInterest } from '@/factories/formStepsFactory/lpInterest';
import translations from '@/i18n/translations/components/listingPage.json';
import useAuthStore from '@/store/modules/auth';
import { useListingStore } from '@/store/modules/listing';
import { formStepperKey, type PayloadFormStepper } from '@/types/event-bus';
import type { FactoryId, Step } from '@/types/formStepsFactory';
import { toDateTime } from '@/utils/time';

const { getFeature } = useFeatureConfig();

const listingStore = useListingStore();
const { amenities, isPreview, listing, listingData, ribbonText } = storeToRefs(listingStore);

const { dialogAuth, user } = storeToRefs(useAuthStore());

const { isFavorite } = useFavorites();
const { badges, cans, icons } = useVarPool();
const bus = useEventBus(formStepperKey);
const { t: tListingPage, locale } = useI18n(translations);
const { formatPrice, formatPropertyCode } = useTheme();
const { capitalize } = format;

const isMounted = ref(false);

const loadingInterest = ref(false);
const submittedInterest = ref(false);

const { factoryId: factoryIdLpAuctionBid, getFormStepsLpAuctionBid } = useStepsLpAuctionBid();
const formStepsLpAuctionBid = getFormStepsLpAuctionBid();

const { factoryId: factoryIdLpInterest, getFormStepsLpInterest } = useStepsLpInterest();
const formStepsLpInterest = getFormStepsLpInterest();

const dialog = ref(false);
const dialogFormFactoryId = ref<FactoryId>();
const dialogFormSteps = ref<Step[]>([]);

const onClickDialogFormStepper = (factoryId: FactoryId, steps: Step[]) => {
  dialogFormFactoryId.value = factoryId;
  dialogFormSteps.value = steps;

  nextTick(() => {
    dialog.value = true;
  });
};

const auctionInfoBtn = computed(() =>
  tListingPage(user.value ? 'btn.auctionLink.label' : 'btn.auctionLink.unlockPrompt')
);

const auctionInfoLabel = computed(() =>
  tListingPage(user.value ? 'section.auction.headerLink' : 'section.auction.unlockPrompt')
);

const onClickAuctionInfo = () => {
  if (!listing.value?.auction || isPreview.value) return;

  if (!user.value) {
    dialogAuth.value = true;
  } else {
    window.open(listing.value.auction.currentRound.auctionLink, '_blank', 'noopener noreferrer');
  }
};

const elCtaWidgetContainer = useTemplateRef<HTMLDivElement>('elCtaWidgetContainer');
const elCtaWidgetContainerBoundings = useElementBounding(elCtaWidgetContainer);
const elCtaWidgetContainerWidth = computed(() => `${elCtaWidgetContainerBoundings.width.value}px`);

const elListingBodyDescription = useTemplateRef<HTMLDivElement>('elListingBodyDescription');
const descriptionBtnShowMore = ref(false);
const descriptionBtnShowMoreVisible = ref(false);

const auctionTitle = computed(() => {
  if (listing.value?.auction?.dateToBeAnnounced) {
    return tListingPage('section.auction.titleMobile');
  }

  const { getComponentConfig } = useTheme();
  const { CtaWidget: config } = getComponentConfig();

  return (
    toDateTime(
      listing.value?.auction?.auctionDate() || '',
      false,
      config.auctionRound.dateFormat,
      locale.value
    ) || ''
  );
});

const onClickDialogFormInterest = () => {
  if (isPreview.value) return;

  loadingInterest.value = true;

  onClickDialogFormStepper(factoryIdLpInterest, formStepsLpInterest.value);
};

// description
const onClickBtnDescriptionShowMore = () => {
  descriptionBtnShowMore.value = !descriptionBtnShowMore.value;
};

const locationInfoBlocks = computed(() => {
  if (!cans.value.includes('location')) return [];

  const configLocationInfo = getFeature('locationInfo')?.options;

  if (!configLocationInfo?.blocks) return [];

  return configLocationInfo.blocks.map(b => {
    switch (b) {
      case 'address':
        return { component: LPAddress };
      case 'map':
        return { component: LPPoisMap, visible: listing.value?.pois?.map?.length };
      case 'proximities':
        return { component: LPPoisProximity, visible: listing.value?.pois?.proximity?.length };
      default:
        throw new Error(`Unsupported block type: ${b}`);
    }
  });
});

watch(
  [isMounted, () => Screen.width, elListingBodyDescription],
  v => {
    if (v[0] && v[2]) {
      setTimeout(() => {
        if (v[2]) {
          descriptionBtnShowMoreVisible.value = v[2].scrollHeight > v[2].clientHeight;
        }
      }, 100);
    }
  },
  { immediate: true }
);

watch(
  () => Screen.gt.sm,
  v => {
    if (v) {
      dialog.value = false;
    }
  }
);

const busListener = (e: PayloadFormStepper) => {
  if (e.factoryId === 'lp-interest') {
    switch (e.event) {
      case 'abort':
        loadingInterest.value = false;
        break;
      case 'complete':
        loadingInterest.value = false;
        submittedInterest.value = true;
        break;
      default:
        break;
    }
  }
};

bus.on(busListener);

onMounted(() => {
  isMounted.value = true;
});

onBeforeUnmount(() => {
  bus.off(busListener);
});
</script>

<style lang="scss">
.listing-body-details {
  --elCtaWidgetContainerWidth: v-bind('elCtaWidgetContainerWidth');
}
</style>
