import collect, { type Collection } from 'collect.js';

import configApp from '@/config/app.json';
import { isLatitude, isLongitude } from '@/utils/gmap';
import { hasDuplicates } from '@/utils/object';

export const removeNonNumbers = (v: Record<string, unknown> | unknown[] | undefined): number[] => {
  const onlyNumbers = collect(v).reject(value => typeof value !== 'number') as Collection<number>;

  return onlyNumbers.reject((value: number) => value > Number.MAX_SAFE_INTEGER).toArray();
};

export const removeNegativeNumbers = (
  v: Record<string, unknown> | unknown[] | undefined
): number[] => {
  const onlyNumbers = collect(v).reject(value => typeof value !== 'number') as Collection<number>;

  return onlyNumbers.reject((value: number) => value < 0).toArray();
};

export const sortNumbers = (v: number[]): number[] => collect(v).sort().toArray();

export const castToString = (v: any | any[]): any => {
  if (Array.isArray(v)) return v.map(i => castToString(i));

  if (['bigint', 'boolean', 'number', 'symbol'].includes(typeof v)) return `${v}`;

  return v;
};

export const removeDuplicates = (
  v: Record<string, unknown> | unknown[] | undefined
): Array<string | number> => collect(v).unique().toArray();

export const removeNonYears = (v: Record<string, unknown> | unknown[] | undefined): number[] => {
  const onlyNumbers = collect(v).reject(value => typeof value !== 'number') as Collection<number>;

  return onlyNumbers
    .reject((value: number) => value > new Date().getFullYear() || value < configApp.minBuildYear)
    .unique()
    .toArray();
};

const clipArray = (v: unknown[] | undefined, numberOfKeysToKeep = 2): Array<string | number> =>
  collect(v).slice(0, numberOfKeysToKeep).toArray();

export const clipToRange = (v: unknown[] | undefined): Array<string | number> => clipArray(v);

export const clipCoordinates = (v: unknown[] | undefined): Array<string | number> =>
  clipArray(v, 4);

export const resetGeoBoxOnDuplicates = (geoBox: number[]): number[] =>
  geoBox !== undefined && hasDuplicates(geoBox) ? [] : geoBox;

export const removeNonCoordinates = (
  v: Record<string, unknown> | unknown[] | undefined
): number[] => {
  const onlyNumbers = collect(v).reject(value => typeof value !== 'number') as Collection<number>;

  return onlyNumbers.reject((value: number) => !isLatitude(value) && !isLongitude(value)).toArray();
};

export const removeNonStrings = (v: Record<string, unknown> | unknown[] | undefined): string[] => {
  const excludeList = ['a/c'];
  const pattern = /^[0-9a-zA-Z._]+$/i;
  const regex = new RegExp(pattern);

  const onlyStrings = collect(v).reject(value => typeof value !== 'string') as Collection<string>;

  return onlyStrings
    .reject((value: string) => {
      if (excludeList.includes(value)) return false;
      return !regex.test(value);
    })
    .unique()
    .sort()
    .toArray();
};

export const removeNonBooleans = (
  v: Record<string, unknown> | unknown[] | undefined
): boolean | string[] => (typeof v === 'boolean' ? v : []);

export const removeRedundantBooleans = (
  v: Record<string, unknown> | unknown[] | undefined
): boolean | string[] => (typeof v === 'boolean' && v === false ? v : []);
