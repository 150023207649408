<template>
  <div v-if="options.length" class="column">
    <div class="sp-filter__label">
      {{ formatLabel(label) }}
    </div>
    <q-option-group
      v-model="selected"
      :options="parkingOptions"
      type="checkbox"
      inline
      class="fit row q-ma-none"
    >
      <template #label="opt">
        <q-list bordered class="row items-center justify-center rounded-borders col">
          <q-item class="row items-center justify-center text-body2 text-secondary">
            {{ opt.value >= 3 ? `${opt.label}+` : opt.label }}
          </q-item>
        </q-list>
      </template>
    </q-option-group>
  </div>
</template>

<script lang="ts" setup>
import { computed } from 'vue';

import type { SearchFilterOptions } from '@/types';
import { formatLabel } from '@/utils/string';

interface Props {
  modelValue: number[];
  label?: string;
  options: number[];
}

const props = withDefaults(defineProps<Props>(), {
  modelValue: () => [],
  label: 'parking',
});
const emit = defineEmits<{
  (event: 'update:modelValue', range: number[]): void;
}>();
const selected = computed({
  get: () => props.modelValue,
  set: numberOfParkingSLots => {
    emit('update:modelValue', numberOfParkingSLots);
  },
});
const parkingOptions = computed(() => {
  const arr: SearchFilterOptions[] = [];
  props.options.forEach((option: number) => {
    const obj = {} as SearchFilterOptions;
    obj.label = option as unknown as string;
    obj.value = option;
    arr.push(obj);
  });
  return arr;
});
</script>

<style lang="scss" scoped>
.q-option-group :deep(> div) {
  display: flex;
  flex: 10000 1 0;
  flex-wrap: wrap;
}

.q-option-group :deep(> div:first-child) {
  margin: 0;
}

.q-option-group :deep(.q-checkbox) {
  display: flex;
  flex: 10000 1 0;
  flex-wrap: wrap;
}

.q-option-group :deep(.q-checkbox__label) {
  flex: 10000 1 0;
}

.q-option-group :deep(.q-checkbox__inner) {
  display: none;
}

.q-option-group :deep(.q-checkbox[aria-checked='true'] .q-list--bordered) {
  border-color: $secondary;
}
</style>
