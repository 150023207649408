<template>
  <div ref="elSPListItemCarousel" class="listing--carousel">
    <ImgRibbon v-if="ribbonVisible" :text="imgRibbonLabel" />

    <div v-if="bannerComponents?.length" class="banner-components">
      <div class="banner__container">
        <component v-bind="c.props" :is="c.component" v-for="(c, i) in bannerComponents" :key="i" />
      </div>
    </div>

    <BadgesRenderer
      v-if="badgesVisible"
      :attributes="badges"
      class="listing--carousel-badges-container"
    />

    <FavoritesBtn
      v-if="favoritesBtnVisible"
      :listing-id="listingId"
      position="absolute-top-right"
    />

    <q-btn
      v-if="images.length && imgTotalVisible"
      class="listing--carousel-total-image-container"
      :icon="icons.mediaAll"
      :label="images.length"
      no-caps
      unelevated
    />

    <div
      v-if="counterVisible"
      class="listing--carousel-counter-container"
      v-text="`${1 + slide}/${images.length}`"
    />

    <div v-if="images.length > 1" class="listing--carousel-slide-navigation">
      <q-btn
        class="listing--carousel-slide-navigation-btn btn-left"
        icon="chevron_left"
        round
        unelevated
        @click.prevent.stop="onClickArrow(-1)"
      />

      <q-btn
        class="listing--carousel-slide-navigation-btn btn-right"
        icon="chevron_right"
        round
        unelevated
        @click.prevent.stop="onClickArrow(1)"
      />
    </div>

    <q-carousel
      v-if="images.length"
      v-model="slide"
      animated
      infinite
      :swipeable="$q.screen.lt.md"
      :padding="false"
      transition-next="slide-left"
      transition-prev="slide-right"
    >
      <q-carousel-slide
        v-for="(img, index) in images"
        :key="index"
        :img-src="img.sizes.sm"
        :name="index"
      />
    </q-carousel>

    <q-img
      v-else
      class="listing__img--fallback"
      :src="fallbackImage"
      fit="cover"
      loading="eager"
      no-spinner
    />
  </div>
</template>

<script lang="ts" setup>
import { Screen } from 'quasar';
import { type ComponentPublicInstance, computed, shallowRef, useTemplateRef } from 'vue';

import FallbackImage from '@/assets/comingSoon.svg';
import BadgesRenderer from '@/components/BadgesRenderer.vue';
import FavoritesBtn from '@/components/Global/FavoritesBtn.vue';
import ImgRibbon from '@/components/Reusable/ImgRibbon.vue';
import type { Attribute } from '@/composables/attributesRenderer';
import { useTheme } from '@/composables/theme';
import type { ListingImages } from '@/types';

type BannerComponent = {
  component: ComponentPublicInstance | string;
  props: Record<string, unknown>;
};

const props = withDefaults(
  defineProps<{
    badges?: Attribute[];
    bannerComponents?: BannerComponent[];
    fallbackImage?: string;
    images?: ListingImages[];
    imgCounter?: boolean;
    imgTotalVisible?: boolean;
    imgRibbonLabel?: string;
    imgRibbonVisible?: boolean;
    favoritesBtnVisible?: boolean;
    listingId: string | number;
  }>(),
  {
    badges: () => [],
    fallbackImage: FallbackImage,
    favoritesBtnVisible: true,
    images: () => [],
    imgCounter: true,
  }
);

const { icons } = useTheme();

const slide = shallowRef(0);

const elSPListItemCarousel = useTemplateRef<HTMLDivElement>('elSPListItemCarousel');

const onClickArrow = (step: number, infinite = true) => {
  let slideNext = slide.value + step;

  if (slideNext < 0) {
    slideNext = infinite ? props.images.length - 1 : 0;
  } else if (slideNext >= props.images.length) {
    slideNext = infinite ? 0 : props.images.length - 1;
  }

  slide.value = slideNext;
};

const counterVisible = computed(() => props.images.length > 1 && props.imgCounter);

const badgesVisible = computed(() => !props.imgRibbonVisible && props.images.length);

const ribbonVisible = computed(() => props.imgRibbonVisible && props.imgRibbonLabel);

const arrowsOpacity = computed(() => (Screen.lt.md ? '1' : '0'));
</script>

<style lang="scss">
.listing-item {
  .listing--carousel-slide-navigation-btn {
    opacity: v-bind('arrowsOpacity');
    transition: opacity 0.5s;
  }

  :hover {
    .listing--carousel-slide-navigation-btn {
      opacity: 1;
    }
  }

  .q-carousel__slide,
  .q-carousel__thumbnail {
    background-color: $util-3;
  }
}
</style>
