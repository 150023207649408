import type { RouteRecordRaw } from 'vue-router';

import { defaultCtype, supportedCtypes } from '@/config/commercialization';
import configLocalization from '@/config/localization.json';
import configTheme from '@/config/theme.json';
import LayoutMain from '@/layouts/LayoutMain.vue';
import ListingPage from '@/pages/ListingPage.vue';
import SearchPage from '@/pages/SearchPage.vue';
import { CommercializationType } from '@/types';

const getLocaleRegex = () => {
  let reg = '';
  configLocalization.availableLocales.forEach((locale, index) => {
    reg = `${reg}${locale}${index !== configLocalization.availableLocales.length - 1 ? '|' : ''}`;
  });
  return `(${reg})`;
};

const getCategoryRegex = () => {
  let reg = '';
  const availableCategories = ['all', 'commercial', 'land', 'residential'];
  availableCategories.forEach((category, index) => {
    reg = `${reg}${category}${index !== availableCategories.length - 1 ? '|' : ''}`;
  });
  return `(${reg})`;
};

const getCtypeRegex = () => {
  let reg = '';
  supportedCtypes.forEach((cType, index) => {
    const cTypeValue = CommercializationType[cType as keyof typeof CommercializationType];
    reg = `${reg}${cTypeValue}${index !== supportedCtypes.length - 1 ? '|' : ''}`;
  });
  return `(${reg})`;
};

export const routes: RouteRecordRaw[] = [
  {
    name: 'index',
    path: `/:locale${getLocaleRegex()}?`,
    redirect: to => {
      return {
        name: 'search',
        params: {
          category: 'residential',
          ctype: defaultCtype,
          locale: to.params.locale,
        },
      };
    },
    meta: { notificationDeleteRequest: true },
  },
  {
    path: `/:locale${getLocaleRegex()}?/login`,
    component: () => import('@/layouts/LayoutLogin.vue'),
    children: [{ path: '', name: 'login', component: () => import('@/pages/LoginPage.vue') }],
  },
  {
    path: `/:locale${getLocaleRegex()}?/customer-panel`,
    component: () => import('@/layouts/LayoutCP.vue'),
    name: 'customer-panel',
    redirect: { name: 'cp-account-settings' },
    meta: { requiresAuth: true },
    children: [
      {
        path: 'favorites',
        name: 'cp-favorites',
        component: () => import('@/pages/CP/CPFavorites.vue'),
        meta: {
          title: 'favorites',
          icon: configTheme.icons.favorites,
        },
      },
      {
        path: 'visits',
        name: 'cp-visits',
        component: () => import('@/pages/CP/CPVisits.vue'),
        meta: {
          title: 'visits',
          icon: 'calendar',
        },
      },
      {
        path: 'offers',
        name: 'cp-offers',
        component: () => import('@/pages/CP/CPOffers.vue'),
        meta: {
          title: 'offers',
          icon: 'myOffers',
        },
      },
      {
        path: 'account-settings',
        name: 'cp-account-settings',
        component: () => import('@/pages/CP/CPAccountSettings.vue'),
        meta: {
          title: 'accountSettings',
          icon: 'settings',
        },
      },
      {
        path: ':pathMatch(.*)*',
        redirect: { name: 'cp-account-settings' },
      },
    ],
  },
  {
    path: `/:locale${getLocaleRegex()}?`,
    component: LayoutMain,
    children: [
      {
        path: `:category${getCategoryRegex()}/:ctype${getCtypeRegex()}`,
        name: 'search',
        component: SearchPage,
        meta: { notificationDeleteRequest: true },
      },
      {
        path: 'listings/:id',
        name: 'listing',
        component: ListingPage,
        meta: { notificationDeleteRequest: true },
      },
      {
        path: 'previews/:id',
        name: 'listing-preview',
        component: ListingPage,
      },
      {
        path: 'list-property',
        name: 'list-property',
        component: () => import('@/pages/ListPropertyPage.vue'),
        meta: { notificationDeleteRequest: true },
      },
      {
        path: 'error/:status',
        name: 'error',
        component: () => import('@/pages/ErrorPage.vue'),
        props: true,
      },
    ],
  },
];
