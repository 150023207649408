import { useQuasar } from 'quasar';
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRoute } from 'vue-router';

import useListingAvailability from '@/composables/useListingAvailability';
import configTheme from '@/config/theme.json';
import type { CommercializationType } from '@/types';
import type { ListingStatuses } from '@/types/api/listing';
import { ordinal } from '@/utils/number';
import { replaceAccentedVowels } from '@/utils/string';
import type Listing from '@/viewModels/Listing';

export const useTheme = () => {
  const { colors, formats, icons, logo, search, customerPanel } = configTheme;
  const { favorites } = customerPanel;

  const { locale, t, n } = useI18n();
  const route = useRoute();

  const q = useQuasar();

  const getComponentConfig = () => configTheme.components;

  const getImgRibbonText = (
    status: ListingStatuses['availabilityStatus'],
    type: CommercializationType
  ) => replaceAccentedVowels(t(useListingAvailability().listingAvailabilityLabel(status, type)));

  const formatAuctionRound = (round: number) => `${ordinal(round, locale.value)} ${t('round')}`;

  const formatPrice = (price: number, formatCurrency: Partial<typeof formats.currency> = {}) => {
    const numberOptions = { ...formats.currency, ...formatCurrency };

    return n(price, {
      ...numberOptions,
      style: 'currency',
      maximumFractionDigits: 0,
      minimumFractionDigits: 0,
    })
      .replaceAll(',', formats.currency.delimiter)
      .replaceAll('.', formats.currency.delimiter);
  };

  const propertyCodeVisible = computed(() =>
    formats.propertyCode.visible.includes(route.name?.toString() || '')
  );

  const propertyCodePrefix = formats.propertyCode.prefix;

  const formatPropertyCode = (code: Listing['propertyCode']) =>
    propertyCodeVisible.value ? `${propertyCodePrefix}${code}` : '';

  const searchResultsGridCols = computed(() => search.resultsCols[q.screen.name]);
  const favoritesResultsGridCols = computed(() => favorites.resultsCols[q.screen.name]);

  const barStyle = {
    height: '9px',
    right: '0px',
    borderRadius: '9px',
    backgroundColor: colors.scrollBar.barStyleBackground,
    width: '9px',
    opacity: '0.2',
  };
  const thumbStyle = {
    right: '4px',
    borderRadius: '5px',
    backgroundColor: colors.scrollBar.thumbStyleBackground,
    width: '5px',
    opacity: '0.75',
  };

  return {
    barStyle,
    colors,
    favoritesResultsGridCols,
    getComponentConfig,
    getImgRibbonText,
    formatAuctionRound,
    formatPrice,
    formatPropertyCode,
    icons,
    logo,
    propertyCodeVisible,
    propertyCodePrefix,
    search,
    searchResultsGridCols,
    thumbStyle,
  };
};
