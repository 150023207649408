<template>
  <div class="nav-bar-drawer">
    <div ref="elNavBar" class="nav-bar-drawer--header">
      <a :href="localizedCompanyUrl" aria-label="home-url">
        <img
          alt="logo"
          src="@/assets/logo.svg"
          :width="logo.mobileDrawerWidth"
          :height="logo.mobileDrawerHeight"
        />
      </a>
      <q-icon
        name="cancel"
        color="secondary"
        size="36px"
        class="cursor-pointer"
        @click="layoutDrawer = false"
      />
    </div>

    <div class="nav-bar-drawer--body">
      <div class="row reverse">
        <AppLanguageSwitcher v-if="configLocalization.availableLocales.length > 1" />
        <q-space />
        <div v-if="user" class="row no-wrap items-center">
          <GlobalAvatar size="2rem" class="q-mr-sm" />
          <div>
            <div class="text-body1-bolder text-secondary" v-text="user.first_name" />
            <div class="text-body1-bolder text-secondary" v-text="user.last_name" />
          </div>
        </div>
      </div>

      <q-expansion-item
        ref="elAccountItems"
        :label="$t('myAccount')"
        :header-class="'text-body1-bolder text-secondary'"
        expand-icon-class="text-secondary"
        dense-toggle
      >
        <div class="expansion-items--container">
          <q-btn
            v-for="routeItem in routesCpFiltered"
            :key="routeItem.path"
            class="nav-bar-drawer--btn"
            no-caps
            unelevated
            @click="menuItemClicked(routeItem)"
          >
            <div class="column items-center">
              <q-icon
                v-if="routeItem.meta?.icon"
                :color="isRouteActive(routeItem.name) ? 'primary' : 'secondary'"
                :name="routeItem.meta.icon"
                size="2rem"
              />
              <span
                :class="isRouteActive(routeItem.name) ? 'text-primary' : 'text-secondary'"
                class="text-body1"
                v-text="routeItem.meta?.title && $t(routeItem.meta.title)"
              />
            </div>
          </q-btn>
        </div>
      </q-expansion-item>
      <q-separator />

      <q-expansion-item
        ref="elCompanyItems"
        :label="format.capitalize(t('company'))"
        :header-class="'text-body1-bolder text-secondary'"
        expand-icon-class="text-secondary"
        dense-toggle
      >
        <div class="expansion-items--container">
          <q-btn
            v-for="companyItem in companyItems"
            :key="companyItem.label"
            class="nav-bar-drawer--btn"
            no-caps
            target="_blank"
            rel="noopener noreferrer"
            :href="companyItem.link || ''"
            unelevated
          >
            <div class="column items-center">
              <q-icon
                v-if="companyItem.icon"
                color="primary"
                :name="companyItem.icon"
                size="2rem"
              />
              <span
                class="text-secondary text-body1"
                v-text="format.capitalize(t(companyItem.label))"
              />
            </div>
          </q-btn>
        </div>
      </q-expansion-item>
      <q-separator />

      <div>
        <div
          class="text-body1-bolder text-secondary q-mb-md"
          v-text="format.capitalize(t('callUs'))"
        />
        <div class="row items-center">
          <q-icon name="phone" size="20px" color="primary" class="q-mr-sm" />
          <div class="text-body1 text-accent">
            <GlobalPhoneNumber :number="configApp.company.phone" />
          </div>
        </div>
      </div>
    </div>

    <div ref="elNavBarFooter" class="nav-bar-drawer--footer">
      <GlobalButton
        v-if="!user"
        :is-outlined="false"
        button-size="regular"
        :label="$t('login')"
        full-width
        class="text-capitalize nav-bar-drawer-btn"
        @click="loginClicked"
      />
      <GlobalButton
        v-else
        :is-outlined="false"
        button-size="regular"
        :label="$t('logout')"
        class="text-capitalize nav-bar-drawer-btn"
        @click="logoutClicked"
      />
      <GlobalButton
        v-if="featureConfig.global.listingSubmission"
        is-outlined
        button-size="regular"
        :label="$t('listYourProperty')"
        type="a"
        :to="listYourPropertyLocalizedLink()"
        class="text-capitalize nav-bar-drawer-btn"
      />
    </div>
  </div>
</template>
<script setup lang="ts">
import { useElementBounding } from '@vueuse/core';
import { storeToRefs } from 'pinia';
import { format, type QExpansionItem } from 'quasar';
import { computed, reactive, useTemplateRef, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import type { RouteRecordName, RouteRecordRaw } from 'vue-router';
import { useRoute, useRouter } from 'vue-router';

import AppLanguageSwitcher from '@/components/AppCommon/AppLanguageSwitcher.vue';
import { useFeatureConfig } from '@/composables/featureConfig';
import { useTheme } from '@/composables/theme';
import useLocalizedLinks from '@/composables/useLocalizedLinks';
import { useNav } from '@/composables/useNav';
import configApp from '@/config/app.json';
import configLocalization from '@/config/localization.json';
import translations from '@/i18n/translations/components/appNavBarDrawer.json';
import useAppStore from '@/store/modules/app';
import useAuthStore from '@/store/modules/auth';

type CompanyInfoItem = {
  label: string;
  icon: string;
  link: string | null;
};

const authStore = useAuthStore();
const { dialogAuth, user } = storeToRefs(authStore);
const { currentLocale, layoutDrawer } = storeToRefs(useAppStore());
const { navLogout, routesCpFiltered } = useNav();
const { listYourPropertyLocalizedLink, localizedCompanyUrl } = useLocalizedLinks();
const { featureConfig } = useFeatureConfig();
const { t } = useI18n(translations);
const { logo } = useTheme();

const companyItems: CompanyInfoItem[] = [
  { label: 'aboutUs', icon: configApp.company.icon, link: configApp.company.links.about },
  { label: 'careers', icon: 'careers', link: configApp.company.links.career },
  { label: 'contactUs', icon: 'contact', link: configApp.company.links.contact },
].filter(el => el.link);

const route = useRoute();
const router = useRouter();

const elAccountItems = useTemplateRef<QExpansionItem>('elAccountItems');
const elCompanyItems = useTemplateRef<QExpansionItem>('elCompanyItems');

const elNavBar = useTemplateRef<HTMLDivElement>('elNavBar');
const elNavBarBounding = reactive(useElementBounding(elNavBar));
const elNavBarBoundingBoundingHeight = computed(() => `${elNavBarBounding.height}px`);

const elNavBarFooter = useTemplateRef<HTMLDivElement>('elNavBarFooter');
const elNavBarFooterBounding = reactive(useElementBounding(elNavBarFooter));
const elNavBarFooterBoundingHeight = computed(() => `${elNavBarFooterBounding.height}px`);

const isRouteActive = (routeName?: RouteRecordName | null) => route.name === routeName;

const menuItemClicked = (routeItem: RouteRecordRaw) => {
  if (user.value) {
    if (isRouteActive(routeItem.name)) {
      layoutDrawer.value = false;
      return;
    }

    router.push({ name: routeItem.name, params: { locale: currentLocale.value } }).then(() => {
      layoutDrawer.value = false;
    });
  } else {
    authStore.setRouteRedirect(routeItem);

    dialogAuth.value = true;
  }
};
const logoutClicked = () => {
  layoutDrawer.value = false;
  navLogout();
};
const loginClicked = () => {
  dialogAuth.value = true;
};

watch(layoutDrawer, v => {
  if (!v) {
    if (elAccountItems.value) elAccountItems.value.hide();
    if (elCompanyItems.value) elCompanyItems.value.hide();
  }
});
</script>

<style lang="scss">
@use 'sass:map';

.nav-bar-drawer {
  width: 100%;
  height: 100%;
  background: white;

  .nav-bar-drawer--header {
    position: relative;
    z-index: 1;
    display: flex;
    align-self: center;
    justify-content: space-between;
    width: 100%;
    padding: 1rem;
    background: white;
    box-shadow: 0 4px 8px rgb(219 225 245 / 25%);
  }

  .nav-bar-drawer--body {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    height: calc(
      100vh - v-bind('elNavBarBoundingBoundingHeight') - v-bind('elNavBarFooterBoundingHeight')
    );
    padding: 1rem;
    padding-bottom: v-bind('elNavBarFooterBoundingHeight');
    overflow: hidden auto;
  }

  .expansion-items--container {
    display: flex;
    flex-wrap: wrap;
    gap: 0.5rem;
    padding: 0.5rem;
  }

  .q-expansion-item {
    .q-expansion-item__container {
      .q-item {
        padding: 0;
      }
    }
  }

  .nav-bar-drawer--btn {
    flex: calc(50% - 0.5rem);
    padding: 1.5rem;
    border-radius: map.get($radius-sizes, 'md');
    box-shadow: 0 1px 10px 3px #d4d1fc40;

    .q-icon {
      margin-bottom: 8px;
    }

    @media (max-width: $breakpoint-xs) {
      flex: 100%;
    }
  }

  .nav-bar-drawer--footer {
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1;
    display: flex;
    gap: 0.5rem;
    width: 100%;
    padding: 1rem;
    background-color: white;
    border-radius: map.get($radius-sizes, md) map.get($radius-sizes, md) 0 0;
    box-shadow: 0 -4px 10px rgb(204 213 237 / 25%);

    .q-btn {
      width: 100%;
    }
    @media (max-width: $breakpoint-xs) {
      flex-direction: column;
    }
  }
}
</style>
