import { computed, toRef } from 'vue';
import { useI18n } from 'vue-i18n';

import { useFeatureConfig } from '@/composables/featureConfig';
import { useTheme } from '@/composables/theme';
import configApp from '@/config/app.json';
import type { Listing } from '@/types';

export type MediaControl = {
  value: 'photos' | 'floorPlans' | 'areaVideo' | 'matterportVideo' | 'mapPhotos' | 'droneVideo';
  visible: boolean;
  label: string;
  icon: string;
};

export const useListingMedia = (listing: Listing) => {
  const { t } = useI18n();
  const localListing = toRef(listing);
  const { featureConfig } = useFeatureConfig();
  const { icons } = useTheme();

  const photosMerged = computed(() => [
    ...localListing.value.images.photos,
    ...(localListing.value.images.mapPhotos || []),
  ]);

  const hasPhotos = computed(() => !!localListing.value.images.photos.length);

  const hasMapPhotos = computed(() => !!localListing.value.images.mapPhotos?.length);

  const hasFloorPlans = computed(() => !!localListing.value.images.floorPlans.length);

  const hasExternalMedia = computed(
    () => localListing.value.externalMedia && !!localListing.value.externalMedia.length
  );

  const hasMatterport = computed(
    () =>
      hasExternalMedia.value &&
      localListing.value!.externalMedia.some(el => el.type === 'matterport')
  );
  const hasDroneVideo = computed(
    () =>
      hasExternalMedia.value &&
      localListing.value!.externalMedia.some(el => el.type === 'drone-video')
  );

  const areaVideoUrl = computed(() => {
    if (!featureConfig.value.listing.areaVideo) return null;
    return localListing.value.locationVideoUrl
      ? localListing.value.locationVideoUrl
      : configApp.company.links.defaultAreaVideo;
  });

  const hasAreaVideo = computed(
    () => featureConfig.value.listing.areaVideo && !!areaVideoUrl.value
  );

  const droneVideoUrl = computed(
    () => localListing.value.externalMedia.find(el => el.type === 'drone-video')?.url
  );
  const matterportVideoUrl = computed(
    () => localListing.value.externalMedia.find(el => el.type === 'matterport')?.url
  );

  const mediaControls = computed<MediaControl[]>(() => [
    {
      icon: icons.mediaPhotos,
      label: t('mediaTypes.photographs'),
      value: 'photos',
      visible: hasPhotos.value,
    },
    {
      icon: '',
      label: t('mediaTypes.mapPhotos'),
      value: 'mapPhotos',
      visible: hasMapPhotos.value,
    },
    {
      icon: icons.mediaTour,
      label: t('mediaTypes.tour'),
      value: 'matterportVideo',
      visible: hasMatterport.value,
    },
    {
      icon: icons.mediaArea,
      label: t('mediaTypes.areaVideo'),
      value: 'areaVideo',
      visible: hasAreaVideo.value,
    },

    {
      icon: icons.mediaDrone,
      label: t('mediaTypes.droneVideo'),
      value: 'droneVideo',
      visible: hasDroneVideo.value,
    },
    {
      icon: icons.mediaFloorPlans,
      label: t('mediaTypes.floorPlan'),
      value: 'floorPlans',
      visible: hasFloorPlans.value,
    },
  ]);

  const excludeMediaControls = (val: MediaControl['value'][]) => {
    return mediaControls.value.filter(c => !val.includes(c.value));
  };

  return {
    areaVideoUrl,
    droneVideoUrl,
    matterportVideoUrl,
    hasDroneVideo,
    hasFloorPlans,
    hasAreaVideo,
    hasMatterport,
    hasPhotos,
    mediaControls,
    photosMerged,
    excludeMediaControls,
  };
};
