<template>
  <a :href="`tel:${tel}`" class="link-undecorated text-primary" v-text="number" />
</template>

<script setup lang="ts">
import { computed } from 'vue';

import configLocalization from '@/config/localization.json';
import { addCallingCodePrefix } from '@/utils/string';

interface Props {
  callingCode?: string;
  number: string | number;
}

const props = withDefaults(defineProps<Props>(), {
  callingCode: configLocalization.callingCode,
});

const tel = computed(() => addCallingCodePrefix(`${props.number}`, props.callingCode));
</script>
