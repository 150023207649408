import _get from 'lodash/get';

import type { ListingFloor } from '@/types/api/listing';
import { toThousands } from '@/utils/number';
import Floor from '@/valueObjects/Floor';

import { formatLabel, trans } from './string';

export const formatBooleanLabel = (val: boolean) => {
  return val ? formatLabel('yes', true) : formatLabel('no', true);
};

export const formatLabelFromArray = (val: Array<string | number>) => {
  return val
    .map(item => (typeof item === 'string' ? formatLabel(item, true) : item))
    .sort()
    .join(', ');
};

export const formatLabelFromNumber = (val: number, unit: string | null = null): string | number => {
  const metric = unit ? trans(unit) : '';
  if (unit === 'sqm')
    return [toThousands(val), metric].filter(v => !['', undefined, null].includes(v)).join(' ');

  return [val as any, metric].filter(v => !['', undefined, null].includes(v)).join(' ');
};

export const humanReadableLabel = (
  key: string,
  rawValue: unknown,
  type: string,
  unit: string | null = null,
  prop: keyof ListingFloor = 'short'
): string | number | null => {
  if (rawValue === null) return null;

  if (key === 'floor') return _get(new Floor(rawValue as ListingFloor), prop);
  if (type === 'array' && Array.isArray(rawValue)) return formatLabelFromArray(rawValue);
  if (type === 'boolean') return formatBooleanLabel(rawValue as boolean);
  if (type === 'number') return formatLabelFromNumber(rawValue as number, unit);
  if (type === 'string' && typeof rawValue === 'string') return formatLabel(rawValue, true);
  if (type === 'string|number') {
    return typeof rawValue === 'string' ? formatLabel(rawValue, true) : (rawValue as string);
  }

  return rawValue as string;
};
