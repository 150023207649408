import configApp from '@/config/app.json';
import { CommercializationType } from '@/types';

const availableCtypes = Object.keys(CommercializationType) as Array<
  keyof typeof CommercializationType
>;

const supportedCtypes = availableCtypes.filter(key => configApp.enabledCtypes.includes(key));

const defaultCtype = CommercializationType[supportedCtypes[0]];

export { availableCtypes, defaultCtype, supportedCtypes };
