<template>
  <div v-if="options.length" class="column">
    <div class="sp-filter__label">
      {{ formatLabel(label) }}
    </div>
    <q-option-group v-model="selected" :options="viewsOptions" type="checkbox" left-label>
      <template #label="opt">
        <q-icon :name="opt.value" size="1.25rem" class="q-my-sm" color="secondary" />
        <span class="q-mx-sm text-body2 text-secondary">
          {{ format.capitalize(opt.label) }}
        </span>
      </template>
    </q-option-group>
  </div>
</template>

<script lang="ts" setup>
import { format } from 'quasar';
import { computed } from 'vue';

import type { SearchFilterOptions } from '@/types';
import { formatLabel } from '@/utils/string';

interface Props {
  modelValue: string[];
  label?: string;
  options: string[];
}

const props = withDefaults(defineProps<Props>(), {
  modelValue: () => [],
  label: 'views',
});
const emit = defineEmits<{
  (event: 'update:modelValue', choices: string[]): void;
}>();
const viewsOptions = computed(() => {
  const arr: SearchFilterOptions[] = [];
  props.options.forEach((option: string) => {
    const obj = {} as SearchFilterOptions;
    obj.label = formatLabel(option);
    obj.value = option;
    arr.push(obj);
  });
  return arr;
});
const selected = computed({
  get: () => props.modelValue,
  set: newValue => {
    emit('update:modelValue', newValue);
  },
});
</script>

<style lang="scss" scoped>
.q-option-group :deep(.q-checkbox) {
  display: flex;
}

.q-option-group :deep(.q-checkbox__bg) {
  border-width: 1px;
}

.q-option-group :deep(.q-checkbox__truthy) {
  stroke-width: 2px;
}

.q-option-group :deep(.q-checkbox__label) {
  flex: 10000 1 0%;
}
</style>
