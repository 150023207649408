<template>
  <div class="share-container">
    <q-btn
      v-bind="qBtnProps"
      :ripple="false"
      unelevated
      no-caps
      class="share--btn"
      @click="onClickBtn"
    />
    <q-dialog v-model="modal" class="share--modal">
      <q-card class="share--modal--container">
        <q-card-section class="share-modal--header">
          <div class="share-modal-header--text" v-text="t('modal.share.title')" />
          <q-btn
            v-close-popup
            class="share-modal-header--close-btn"
            icon="cancel"
            flat
            round
            dense
          />
        </q-card-section>

        <q-card-section class="share-modal--body">
          <q-btn
            :label="copied ? t('btn.share.copyLink.submitted') : t('btn.share.copyLink.submit')"
            icon="copy"
            unelevated
            :ripple="false"
            align="left"
            class="share-modal--copy-btn"
            :class="{
              copied,
            }"
            no-caps
            @click="onClickCopyBtn"
          />
          <q-btn
            class="share-modal--email-btn"
            :label="t('btn.share.email')"
            icon="email"
            align="left"
            type="a"
            :href="hrefEmail"
            unelevated
            :ripple="false"
            no-caps
            @click="onClickEmailBtn"
          />
        </q-card-section>
      </q-card>
    </q-dialog>
  </div>
</template>

<script lang="ts" setup>
import type { QBtnProps } from 'quasar';
import { copyToClipboard, format } from 'quasar';
import { ref, watch } from 'vue';
import { useI18n } from 'vue-i18n';

import configApp from '@/config/app.json';
import translations from '@/i18n/translations/components/listingPage.json';

const { company } = configApp;

const { t } = useI18n(translations);

defineProps<{
  qBtnProps?: QBtnProps;
}>();

const emit = defineEmits<{
  (e: 'click:copy'): void;
  (e: 'click:email'): void;
  (e: 'click:share'): void;
}>();

const modal = defineModel<boolean>();
const copied = ref(false);
const hrefEmail = ref('');
const companyName = format.capitalize(company.name);

const mailSubject = `subject=Listing shared by ${companyName}&`;
const mailBody = `body=I would like to share this listing i found by ${companyName}, ${window.location.href}`;
hrefEmail.value = `mailto:?${mailSubject.replace(' ', '%20') + mailBody.replace(' ', '%20')}`;

const onClickBtn = () => {
  modal.value = !modal.value;
  copied.value = false;
  emit('click:share');
};

const onClickCopyBtn = () => {
  copyToClipboard(window.location.href)
    .then(() => {
      copied.value = true;
      emit('click:copy');

      const el = document.querySelector('.share-modal--copy-btn .q-btn__content span');
      if (el) {
        if (!el.classList.contains('animation-bounce')) {
          el.classList.add('animation-bounce');
          setTimeout(() => {
            el.classList.remove('animation-bounce');
          }, 900);
        }
      }
    })
    .catch(() => {
      // err
    });
};

const onClickEmailBtn = () => {
  emit('click:email');
};

watch(
  () => copied.value,
  v => {
    if (v) {
      setTimeout(() => {
        copied.value = false;
      }, 1000);
    }
  }
);
</script>

<style lang="scss">
@use 'sass:map';

.share--modal {
  .share--modal--container {
    padding: 1rem 1.5rem;

    @media (min-width: $breakpoint-xs) {
      min-width: 23.75rem;
    }
  }

  .share-modal--header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0;
    margin-bottom: 1.5rem;

    .share-modal-header--text {
      margin-right: 1rem;
      font-size: 1.125rem;
      font-weight: 800;
      color: $secondary;
    }

    .share-modal-header--close-btn {
      color: $primary;
      background: $primary-3;

      .q-icon {
        width: 1.25rem;
        height: 1.25rem;
      }
    }
  }

  .share-modal--body {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    align-items: flex-start;
    justify-content: center;
    padding: 0;
    color: $accent;

    .q-btn {
      width: 100%;
      padding: 0;

      .q-icon {
        width: 1.4375rem;
        height: 1.4375rem;
        color: $accent;
      }

      &.copied span {
        color: $primary;
        transition: color 500ms;
      }
    }
  }
}

@keyframes bounce-scale {
  0% {
    transform: scale(1);
  }

  30% {
    transform: scale(1.05);
  }

  50% {
    transform: scale(0.9);
  }

  100% {
    transform: scale(1);
  }
}

.animation-bounce {
  animation-name: bounce-scale;
  animation-duration: 400ms;
}
</style>
