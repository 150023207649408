import type { RouteLocationNormalized } from 'vue-router';
import { createRouter, createWebHistory } from 'vue-router';

import { useApiClient } from '@/composables/api/client';
import { useApiConfig } from '@/composables/api/config';
import { useCookies } from '@/composables/useCookies';
import i18n from '@/i18n';
import resolveFeatureGuard from '@/router/featuresRouteGuard';
import { routes } from '@/router/routes';
import useAppStore from '@/store/modules/app';
import useAuthStore from '@/store/modules/auth';
import { filtersHandler } from '@/utils/requestFiltersHandler';

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes,
});

const setUtmSource = (to: RouteLocationNormalized) => {
  const utmSourceKey = 'utm_source';
  const source = to.query[utmSourceKey];

  if (typeof source === 'string') {
    const { setCookieItem } = useCookies();

    setCookieItem(utmSourceKey, source);
  }
};

router.beforeResolve(async to => {
  const { error } = useApiClient();
  const appStore = useAppStore();
  const authStore = useAuthStore();

  if (!appStore.config) {
    const { data } = await useApiConfig().index();

    appStore.config = data.data;
  }

  if (authStore.user === undefined) {
    await authStore.me();
  }

  if (!authStore.user && to.meta.requiresAuth) {
    return { name: 'login' };
  }

  switch (to.name) {
    case 'login':
      if (authStore.user) {
        return { path: appStore.fallbackRoutePath };
      }
      break;
    case 'error':
      if (!error.value) {
        return { path: appStore.fallbackRoutePath };
      }
      break;
    default:
      break;
  }

  const triggerFeatureGuard = resolveFeatureGuard(appStore.config.features, to.name?.toString());

  if (triggerFeatureGuard) {
    return {
      name: 'error',
      params: { status: 404 },
    };
  }

  return true;
});

router.beforeEach((to, from) => {
  const paramLocale = to.params?.locale?.toString();

  if (paramLocale && i18n.global.availableLocales.includes(paramLocale)) {
    i18n.global.locale.value = paramLocale;
  }

  setUtmSource(to);

  filtersHandler.handle(to, from);

  return true;
});

export default router;
