<template>
  <div>
    <div class="listing-body__text--title" v-text="t('section.location.proximity.title')" />

    <div
      v-for="proximity in poisProximity"
      :key="proximity.type"
      class="q-py-md"
      :class="{ row: $q.screen.gt.xs }"
    >
      <q-icon
        class="q-pb-xs q-mr-md"
        :name="ProximityPoints[proximity.type]"
        color="secondary"
        size="48px"
      />

      <div>
        <div class="q-pb-xs text-secondary">
          <span
            class="text-body2-bold"
            v-text="`${t(`section.location.proximity.labels.${proximity.type}`)} - `"
          />

          <span class="text-body2" v-text="proximity.name" />
        </div>

        <div class="row">
          <q-icon class="q-pb-xs q-mr-xs" name="map" color="accent" size="24px" />
          <div class="text-body2 text-accent q-mr-md">
            {{ getDistanceWithUnit(proximity.properties.distance) }}
          </div>

          <template v-if="proximity.properties.duration_driving <= hardLimits.drive">
            <q-icon class="q-pb-xs q-mr-xs" name="driving" color="accent" size="24px" />
            <div class="text-body2 text-accent q-mr-md">
              {{ getDurationWithUnit(proximity.properties.duration_driving) }}
              {{ t('section.location.proximity.drive') }}
            </div>
          </template>

          <q-icon
            v-if="
              proximity.properties.duration_transit <= hardLimits.transit &&
              proximity.properties.duration_transit > 0
            "
            class="q-pb-xs q-mr-xs"
            name="subway_station"
            color="accent"
            size="24px"
          />
          <div
            v-if="
              proximity.properties.duration_transit <= hardLimits.transit &&
              proximity.properties.duration_transit > 0
            "
            class="text-body2 text-accent q-mr-md"
          >
            {{ getDurationWithUnit(proximity.properties.duration_transit) }}
            {{ t('section.location.proximity.transit') }}
          </div>

          <q-icon
            v-if="proximity.properties.duration_walking <= hardLimits.walk"
            class="q-pb-xs q-mr-xs"
            name="walking"
            color="accent"
            size="24px"
          />
          <div
            v-if="proximity.properties.duration_walking <= hardLimits.walk"
            class="text-body2 text-accent q-mr-md"
          >
            {{ getDurationWithUnit(proximity.properties.duration_walking) }}
            {{ t('section.location.proximity.walk') }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { storeToRefs } from 'pinia';
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';

import translations from '@/i18n/translations/components/listingPage.json';
import { useListingStore } from '@/store/modules/listing';
import { ProximityPoints } from '@/types/api/proximity';

const { listing } = storeToRefs(useListingStore());

const { t } = useI18n(translations);

const poisProximity = computed(() => listing.value?.pois?.proximity || []);

const hardLimits = {
  walk: 55 * 60,
  drive: 2 * 60 * 60,
  transit: 2 * 60 * 60,
};

const getDistanceWithUnit = (distance: number) =>
  distance > 1000
    ? `${Math.round(distance / 1000)}${t('section.location.proximity.units.kilometer')}`
    : `${distance}${t('section.location.proximity.units.meter')}`;

const getDurationWithUnit = (duration: number) => {
  if (duration < 60) return `1${t('section.location.proximity.units.minute')}`;

  return duration > 3600
    ? `${Math.round(duration / 60 / 60)}${t('section.location.proximity.units.hour')}`
    : `${Math.round(duration / 60)}${t('section.location.proximity.units.minute')}`;
};
</script>

<style lang="scss" scoped>
.q-slider__marker-labels-container {
  font-weight: 600;
}
</style>
