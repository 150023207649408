<template>
  <div class="row wrap justify-start items-stretch content-center">
    <div
      class="col row wrap content-center text-subtitle1 text-secondary"
      v-text="formatLabel(label, true)"
    />
    <q-toggle v-model="selected" size="42px" />
  </div>
</template>

<script lang="ts" setup>
import { computed } from 'vue';

import { formatLabel } from '@/utils/string';

interface Props {
  modelValue?: boolean;
  label?: string;
}

const props = defineProps<Props>();

const emit = defineEmits<{
  (event: 'update:modelValue', choice: boolean | undefined): void;
}>();

const selected = computed({
  get: () => props.modelValue || false,
  set: val => {
    emit('update:modelValue', val === false ? undefined : val);
  },
});
</script>
