<template>
  <div class="column">
    <div class="sp-filter__label">
      {{ formatLabel(label) }}
      <span class="text-secondary text-body2">({{ $t('byCar') }})</span>
    </div>
    <q-slider
      v-model="selected"
      class="sp-filter-q-slider__driving"
      color="primary"
      markers
      :marker-labels="options"
      marker-labels-class="text-secondary text-body2"
      switch-label-side
      switch-marker-labels-side
      track-size="1px"
      :min="0"
      :max="4"
    />
  </div>
</template>

<script lang="ts" setup>
import isEmpty from 'lodash/isEmpty';
import { computed } from 'vue';

import { formatLabel } from '@/utils/string';

interface Props {
  modelValue: number[];
  label?: string;
}

const props = withDefaults(defineProps<Props>(), {
  modelValue: () => [],
  label: 'proximityToPort',
});
const emit = defineEmits<{
  (event: 'update:modelValue', choices: number[]): void;
}>();

const choices: { [key: string]: number } = { 1: 15, 2: 30, 3: 60, 4: 120 };
const selected = computed({
  get: () => {
    if (!isEmpty(props.modelValue)) {
      const val = Object.keys(choices).find(key => choices[key] === props.modelValue[1] / 60);

      return val !== undefined ? +val : 0;
    }

    return 0;
  },
  set: newValue => {
    if (newValue === 0) {
      emit('update:modelValue', []);
    } else {
      emit('update:modelValue', [1, choices[newValue] * 60]);
    }
  },
});

const options = { 0: 'Any', 1: { label: "<15'" }, 2: "<30'", 3: "<60'", 4: "<120'" };
</script>

<style lang="scss">
.sp-filter-q-slider__driving {
  .q-slider__marker-labels:first-child {
    left: 12px !important;
  }

  .q-slider__marker-labels:last-child {
    left: calc(100% - 12px) !important;
  }

  .q-slider__track {
    border-radius: 0;
  }

  .q-slider__markers--h:after {
    width: 1px !important;
  }

  .q-slider__markers {
    position: absolute;
    top: -4px;
    height: 10px;
    background-image: repeating-linear-gradient(
      to right,
      currentcolor,
      currentcolor 1px,
      rgb(255 255 255 / 0%) 0,
      rgb(255 255 255 / 0%)
    );
  }
}
</style>
